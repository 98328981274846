import Link from 'next/link';

interface Props {
    statusCode: number;
    title?: string;
    description?: string;
}

export default function CustomError(props: Props) {
    return (
        <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <div className="mx-auto max-w-max">
                <main className="sm:flex">
                    <p className="text-4xl font-extrabold text-primary-color sm:text-5xl">{props.statusCode}</p>
                    <div className="sm:ml-6">
                        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                                {props.title}
                            </h1>
                            <p className="mt-1 text-base text-pulppo-status-dark-disabled">{props.description}</p>
                        </div>
                        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                            <Link href="/">
                                <a className="inline-flex items-center rounded-md border border-transparent bg-primary-color px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-200 focus:ring-offset-2">
                                    Regresar a inicio
                                </a>
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
